import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Open Source Projects" mdxType="Title" />
    <ul>
      <li parentName="ul"><a href="https://github.com/dehamzah/instagram-flutter" target="_blank">Instagram Flutter</a></li>
      <li parentName="ul"><a href="https://github.com/dehamzah/BeriduRN" target="_blank">Berita Dunia React Native</a></li>
      <li parentName="ul"><a href="https://github.com/dehamzah/starter-theme-openedx" target="_blank">Starter Theme OpenEdx</a></li>
      <li parentName="ul"><a href="https://github.com/IndonesiaX/midtrans-nodeX" target="_blank">Midtrans NodeX</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      